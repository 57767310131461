<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
    Bonita Covid-19 Registration
      <v-spacer></v-spacer>
    <v-btn icon :color="this.$store.state.useCookie==1?'white':'red lighten-2'" @click='invCookie()'>
      <v-icon>
        mdi-cookie
      </v-icon>
    </v-btn>
    </v-app-bar>
    <v-main>
      <Home/>
    </v-main>
  </v-app>
</template>

<script>
import Home from './components/Home';

export default {
  name: 'App',

  components: {
    Home,
  },

  data: () => ({
    //
  }),
  methods:{
    invCookie(){
      let c = this.$store.state.useCookie;
      if(c == 1){
        c = 0;
      }else{
        c = 1;
      }
      this.$store.commit('setUseCookie',c);
      this.$cookies.set("useCookie",c);
    }
  },
  mounted(){

  }
};
</script>
