<template>
  <v-container>
    <v-card>
      <v-card-title>Please register</v-card-title>
      <v-card-text>
        <v-form ref="form1">
          <v-text-field :rules="[stdRules.required]" v-model="userdata.firstname" label="First name"></v-text-field>
          <v-text-field :rules="[stdRules.required]" v-model="userdata.lastname" label="Last name"></v-text-field>
          <v-text-field :rules="[stdRules.required]" v-model="userdata.mobile" label="Telephone number"></v-text-field>
          <v-select label="Number of people" :rules="[stdRules.required]" v-model="userdata.pax" :items="pax_data"></v-select>
          <v-menu
                    v-model="datepicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="userdata.visit"
                        label="Visit date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="userdata.visit"
                      @input="datepicker = false"
                    ></v-date-picker>
                  </v-menu>    

      <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="userdata.time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="userdata.time"
            label="Visit Time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            @click="setDontRunTime()"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="userdata.time"
          full-width
          @click:minute="$refs.menu.save(userdata.time)"
        ></v-time-picker>
      </v-menu>
        <v-btn color="green lighten-2" @click="register()">Register</v-btn>
        </v-form>
      </v-card-text>
    </v-card>

      <v-dialog v-model="showDialog" width="50%">
        <v-card>
          <v-card-title>Thank you for registering</v-card-title>
          <v-card-text>Your data will only be used if the health authorities ask for it.</v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="loading" fullscreen>
        <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
          <v-layout justify-center align-center>
            <v-progress-circular
              indeterminate
              color="primary">
            </v-progress-circular>
          </v-layout>
        </v-container>
      </v-dialog>
      <v-dialog v-model="errorDialog" width="50%">
        <v-card>
          <v-card-title class='red lighten-1'>
            <v-icon
                large
                left
              >
                mdi-alert
              </v-icon>
            Error</v-card-title>
          <v-card-text>{{error}}<br />
Please try again later or ask your host for assistance
          </v-card-text>
        </v-card>
      </v-dialog>    
  </v-container>
</template>

<script>
  import GlobalHelper from "../mixins/GlobalHelper.js"
  export default {
    name: 'Home',
    mixins: [GlobalHelper],
    data: () => ({
      userdata:{
        time: new Date().getHours().toString().padStart(2,'0') + ":" + new Date().getMinutes().toString().padStart(2,'0') ,
        visit:new Date().toISOString().substr(0,10),
      },
      errorDialog:false,
      error:"",
      loading:false,
      datepicker:false,
      menu2:false,
      btnRegisterDisabled:false,
      showDialog:false,
      pax_data:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
      dontRunTime:false,
      //time:null
    }),
    methods:{
      setDontRunTime(){
        this.dontRunTime = true;
      },
      refreshDateTime(){
        this.userdata.time = new Date().getHours().toString().padStart(2,'0') + ":" + new Date().getMinutes().toString().padStart(2,'0');
        this.userdata.visit = new Date().toISOString().substr(0,10)
      },
      runTime(){
        let that = this;
        setInterval(function () {
          if(!that.dontRunTime){
            that.refreshDateTime();
          }
        },10000)   
      },
      register(){
        if(!this.$refs.form1.validate()){
          return;
        }
        let visit  = this.userdata.visit + " " + this.userdata.time;
        let data = {...this.userdata};
        data.visit = visit;
        this.loading = true;
        this.sendToServer({action:'register',data}).then(()=>{
          if(this.$store.state.useCookie == 1){
            let cookie = {
              firstname:this.userdata.firstname,
              lastname:this.userdata.lastname,
              mobile:this.userdata.mobile
            }
            this.$cookies.set("data",cookie);    
            this.$delete(this.userdata,"pax");
          }else{
            this.userdata = {};
          }
          this.$refs.form1.resetValidation()
          this.refreshDateTime();
          this.loading = false
          this.showDialog = true
        }).catch((e)=>{
          this.error = e
          this.errorDialog = true
        }).finally(()=>{
          this.loading=false
          this.btnRegisterDisabled = false
          this.dontRunTime = false;
        })        
      }
    },
    created(){
      this.runTime();
    },
    mounted(){
      let c = this.$cookies.get('useCookie');
      if(c == 0){
        this.$store.commit('setUseCookie',0);
      }
      if(this.$store.state.useCookie == 0){
        return;
      }

      let d  = this.$cookies.get("data")
      if(d != undefined && d.firstname != undefined && d.firstname != ""){
        this.userdata = d;
      }        
      this.refreshDateTime();
    }
  }
</script>
