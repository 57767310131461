import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    useCookie:1,
  },
  mutations: {
    setUseCookie(state,useCookie){
      state.useCookie = useCookie
    },
  },
  actions: {
  },
  modules: {
  }
})
