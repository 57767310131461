// define a mixin object
import axios from 'axios'
import Qs from 'qs'

const GlobalHelper = {
  data(){
    return {
      stdRules:{
        required: v => !!v || "This field is required",
        email: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
              ],
        notneg: v => parseInt(v) > 0 || "This field is required"
      },
    }
  },
  methods: {
    sendToServer(payload){
      return new Promise((resolve,reject)=>{
        //console.log(payload);
        //axios.post("https://mister-paradise.com/booknow/process.php",Qs.stringify(payload)).then(response => {
        axios.post("https://appscaped.com/api.php",Qs.stringify(payload)).then(response => {
          if(!response.data.result){
            if(response.data.data){
              reject("error: " + response.data.data)
            }else{
              reject("error: " + response.data)
            }
          }else{
            resolve(response.data.data)
          }
        }).catch(err => {
            reject('Something went wrong:' + err)
        })
      })
    }
  },
  created(){
  }
}
export default GlobalHelper
